import { Link } from "gatsby" 
import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
 
const Blog = ({ data, classes , pageContext }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageContext.title} | Spark Performance</title> 
    </Helmet>
    <Layout>

        <div>
            blog
        </div>
        
        {pageContext?.moduleItems?.map((post, i) => (
          <Link key={i} to={'/blog/' + post.url} className="text-base font-medium text-gray-500 hover:bg-gray-100 px-6 py-3 rounded-md border-b-2 border-transparent" activeClassName=" text-red-500 ">
            <h2>
              {post.name}
            </h2>
          </Link>
        ))}

    </Layout>
  </>
)
 
export default Blog